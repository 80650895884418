import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "confirm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, _toDisplayString(_ctx.content), 1),
    _createElementVNode("div", null, [
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('nocallback')))
      }, _toDisplayString(_ctx.$t('no')), 1),
      _createElementVNode("button", {
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('yescallback')))
      }, _toDisplayString(_ctx.$t('yes')), 1)
    ])
  ]))
}