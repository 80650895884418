import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, TransitionGroup as _TransitionGroup, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "video local-media" }
const _hoisted_2 = {
  ref: "localMediaEl",
  autoplay: "",
  playsinline: "",
  muted: ""
}
const _hoisted_3 = { class: "viewers-medias" }
const _hoisted_4 = ["muted"]
const _hoisted_5 = { class: "video screensharing" }
const _hoisted_6 = {
  ref: "localDisplayMediaEl",
  autoplay: "",
  playsinline: ""
}
const _hoisted_7 = {
  class: "ping",
  ref: "ping"
}
const _hoisted_8 = { class: "viewers" }
const _hoisted_9 = { class: "viewers-count" }
const _hoisted_10 = {
  key: 1,
  class: "webrtc-create"
}
const _hoisted_11 = {
  key: 0,
  class: "webrtc-toggles"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_account_icon = _resolveComponent("account-icon")!
  const _component_face_agent_icon = _resolveComponent("face-agent-icon")!
  const _component_eye_icon = _resolveComponent("eye-icon")!
  const _component_confirm = _resolveComponent("confirm")!
  const _component_call_icon = _resolveComponent("call-icon")!
  const _component_video_call_icon = _resolveComponent("video-call-icon")!
  const _component_play_icon = _resolveComponent("play-icon")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_microphone_icon = _resolveComponent("microphone-icon")!
  const _component_microphone_off_icon = _resolveComponent("microphone-off-icon")!
  const _component_video_icon = _resolveComponent("video-icon")!
  const _component_video_off_icon = _resolveComponent("video-off-icon")!
  const _component_camera_switch_icon = _resolveComponent("camera-switch-icon")!

  return (_ctx.isReady)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["webrtc", { 'show-overlay': _ctx.showOverlay}]),
        onTouchend: _cache[14] || (_cache[14] = ($event: any) => (_ctx.toggleOverlay()))
      }, [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          (!_ctx.isCameraEnabled)
            ? (_openBlock(), _createBlock(_component_account_icon, {
                key: 0,
                class: "icon"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("video", _hoisted_2, null, 512)
        ], 512), [
          [_vShow, _ctx.localMedia]
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.viewers, (viewer) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "video",
                key: viewer.id
              }, [
                (!viewer.isCameraEnabled)
                  ? (_openBlock(), _createBlock(_component_face_agent_icon, {
                      key: 0,
                      class: "icon"
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("video", {
                  autoplay: "",
                  playsinline: "",
                  ref_for: true,
                  ref: viewer.id,
                  muted: viewer && viewer.userMedia && viewer.userMedia === _ctx.$refs.localDisplayMediaEl.srcObject
                }, null, 8, _hoisted_4)
              ])), [
                [_vShow, viewer && viewer.userMedia && viewer.userMedia !== _ctx.$refs.localDisplayMediaEl.srcObject]
              ])
            }), 128))
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_5, [
          (_ctx.viewers[0] && !_ctx.viewers[0].isCameraEnabled && (!_ctx.$refs.localDisplayMediaEl.srcObject || _ctx.viewers[0].userMedia === _ctx.$refs.localDisplayMediaEl.srcObject))
            ? (_openBlock(), _createBlock(_component_face_agent_icon, {
                key: 0,
                class: "icon"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("video", _hoisted_6, null, 512)
        ], 512), [
          [_vShow, _ctx.localDisplayMedia || (_ctx.viewers && _ctx.viewers.length)]
        ]),
        (_ctx.localDisplayMedia && (!_ctx.viewers[0] || _ctx.viewers[0].userMedia !== _ctx.$refs.localDisplayMediaEl.srcObject))
          ? (_openBlock(), _createBlock(_Transition, {
              key: 0,
              name: "fade"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", _hoisted_7, null, 512), [
                  [_vShow, _ctx.pingTimeout]
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, [
            _createVNode(_component_eye_icon, { class: "icon" }),
            _createTextVNode(" " + _toDisplayString(_ctx.viewers.length), 1)
          ])
        ], 512), [
          [_vShow, _ctx.signaling]
        ]),
        _createVNode(_TransitionGroup, {
          name: "list",
          tag: "div",
          class: "notifications"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notif) => {
              return (_openBlock(), _createBlock(_component_confirm, {
                key: notif.id,
                content: _ctx.$t('ask', [notif.content]),
                onNocallback: ($event: any) => (_ctx.denyViewer(notif.id)),
                onYescallback: ($event: any) => (_ctx.allowViewer(notif.id, notif.content))
              }, null, 8, ["content", "onNocallback", "onYescallback"]))
            }), 128))
          ]),
          _: 1
        }),
        (!_ctx.askingPermissions && !_ctx.signaling)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.hasUserMediaAPI && !_ctx.isKindVideocall && !_ctx.isKindScreensharing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createSession(_ctx.SESSION_KIND.CALL))),
                    onTouchend: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    type: "button"
                  }, [
                    _createVNode(_component_call_icon, { class: "icon" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('start_call')), 1)
                  ], 32))
                : _createCommentVNode("", true),
              (_ctx.hasUserMediaAPI && !_ctx.isKindAudiocall && !_ctx.isKindScreensharing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createSession(_ctx.SESSION_KIND.VIDEOCALL))),
                    onTouchend: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"])),
                    type: "button"
                  }, [
                    _createVNode(_component_video_call_icon, { class: "icon" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('start_videocall')), 1)
                  ], 32))
                : _createCommentVNode("", true),
              (_ctx.hasMediaDeviceAPI && !_ctx.isKindAudiocall && !_ctx.isKindVideocall)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 2,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.createSession(_ctx.SESSION_KIND.SCREENSHARE))),
                    onTouchend: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
                    type: "button"
                  }, [
                    _createVNode(_component_play_icon, { class: "icon" }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('start_screensharing')), 1)
                  ], 32))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("button", {
                onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.destroySession && _ctx.destroySession(...args))),
                onTouchend: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.toggleOverlay(true)), ["stop"])),
                type: "button",
                class: "webrtc-destroy"
              }, [
                _createVNode(_component_close_icon)
              ], 32),
              (!_ctx.displayMediaId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createElementVNode("button", {
                      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.toggleMicrophone && _ctx.toggleMicrophone(...args))),
                      onTouchend: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.toggleOverlay(true)), ["stop"])),
                      type: "button",
                      class: "webrtc-microphone"
                    }, [
                      (_ctx.isMicrophoneEnabled)
                        ? (_openBlock(), _createBlock(_component_microphone_icon, {
                            key: 0,
                            class: "icon"
                          }))
                        : (_openBlock(), _createBlock(_component_microphone_off_icon, {
                            key: 1,
                            class: "icon"
                          }))
                    ], 32),
                    _withDirectives(_createElementVNode("button", {
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleCamera && _ctx.toggleCamera(...args))),
                      onTouchend: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.toggleOverlay(true)), ["stop"])),
                      type: "button",
                      class: "webrtc-camera"
                    }, [
                      (_ctx.isCameraEnabled)
                        ? (_openBlock(), _createBlock(_component_video_icon, {
                            key: 0,
                            class: "icon"
                          }))
                        : (_openBlock(), _createBlock(_component_video_off_icon, {
                            key: 1,
                            class: "icon"
                          }))
                    ], 544), [
                      [_vShow, !_ctx.isKindAudiocall]
                    ]),
                    _withDirectives(_createElementVNode("button", {
                      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.switchCamera && _ctx.switchCamera(...args))),
                      onTouchend: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.toggleOverlay(true)), ["stop"])),
                      class: _normalizeClass([{ hidden: !_ctx.isCameraEnabled }, "webrtc-camera-switch"]),
                      type: "button"
                    }, [
                      _createVNode(_component_camera_switch_icon, { class: "icon" })
                    ], 34), [
                      [_vShow, !_ctx.isKindAudiocall && _ctx.hasFacingMode]
                    ])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ], 34))
    : (_openBlock(), _createElementBlock("div", _hoisted_12, [
        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t(_ctx.isExpired ? 'expired' : 'incompatible')), 1)
      ]))
}